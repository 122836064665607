import React from 'react';
import { Scatter } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';
import { startOfWeek, subWeeks, addWeeks } from 'date-fns';
import { useTranslation } from 'react-i18next';


Chart.register(...registerables);

const PerformanceChart = ({ responses, currentWeek, setCurrentWeek, hideWeekOptions }) => {
    const { t } = useTranslation();

    const handlePreviousWeek = () => {
        setCurrentWeek(subWeeks(currentWeek, 1));
    };

    const handleNextWeek = () => {
        setCurrentWeek(addWeeks(currentWeek, 1));
    };

    const handleCurrentWeek = () => {
        setCurrentWeek(startOfWeek(new Date(), { weekStartsOn: 1 }));
    };

    const processResponsesToDataPoints = (responses) => {
        const points = [];

        responses.forEach(response => {
            if (!response.score || !response.timestamp) {
                return;
            }

            const date = new Date(response.timestamp);
            const day = date.getDay();
            const hour = date.getHours();

            if (day >= 1 && day <= 5 && hour >= 8 && hour <= 17) {
                const dayIndex = day - 1;
                const hourIndex = hour - 8;
                points.push({
                    x: hourIndex,
                    y: dayIndex,
                    r: 10,
                    score: response.score,
                });
            }
        });

        return points;
    };

    const dataPoints = processResponsesToDataPoints(responses);

    const data = {
        datasets: [
            {
                label: 'Performance Score',
                data: dataPoints,
                backgroundColor: (ctx) => {
                    const value = ctx.raw ? ctx.raw.score : 0;
                    return value ? `rgba(31, 109, 162, ${value / 10})` : 'rgba(255, 255, 255, 0.1)';
                },
            },
        ],
    };

    const options = {
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: t('performanceChart.timeOfDay'),
                },
                ticks: {
                    callback: (value) => {
                        const timeLabels = ['8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM'];
                        return timeLabels[value] || '';
                    },
                },
            },
            y: {
                type: 'linear',
                title: {
                    display: true,
                    text: t('performanceChart.dayOfTheWeek'),
                },
                ticks: {
                    callback: (value) => {
                        const dayLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
                        return dayLabels[value] || '';
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: t('performanceChart.performanceHeatmap'),
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const score = context.raw ? context.raw.score : 0;
                        return t('performanceChart.score', { score });
                    }
                }
            },
        },
        elements: {
            point: {
                radius: (ctx) => {
                    const value = ctx.raw ? ctx.raw.score : 0;
                    return value ? (value / 10) * 15 : 5;
                },
            },
        },
    };


    return (
        <div className="tab-content">
            <div style={{ marginLeft: '100px', display: 'flex', alignItems: 'center' }}>
                <h3 style={{ marginRight: '10px' }}>{t('performanceChart.performanceHeatmap')}</h3>
            </div>
            {!hideWeekOptions && (
                <div>
                    <button onClick={handlePreviousWeek}>{t('performanceChart.previousWeek')}</button> &nbsp;
                    <button onClick={handleCurrentWeek}>{t('performanceChart.currentWeek')}</button>&nbsp;
                    <button onClick={handleNextWeek}>{t('performanceChart.nextWeek')}</button>
                </div>
            )}
            <div style={{ height: '250px', width: '100%' }}>
                <Scatter data={data} options={options} />
            </div>
        </div>
    );
};

export default PerformanceChart;
