import React, { useEffect, useState } from 'react';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { completeSurvey } from '../../../../services/surveyService';
import { getScheduledNotificationForObservationPeriod, extendObservationPeriod } from '../../../../services/notificationsService';
import { fetchQ20Data } from '../../../../services/surveyService';
import { deleteDailyNotifications } from '../../../../services/notificationsService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert } from '@mui/material';
import SurveyCalendar from './SurveyCalendar';
import { addDays, isAfter, format, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

const SurveyProgress = ({ userId }) => {
    const [daysCompleted, setDaysCompleted] = useState(0);
    const [totalSurveyDays, setTotalSurveyDays] = useState(0);
    const [loading, setLoading] = useState(true);
    const [surveyPeriods, setSurveyPeriods] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [questionsAnsweredToday, setQuestionsAnsweredToday] = useState(0);
    const [isInObservationPeriod, setIsInObservationPeriod] = useState(true);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [canRestartObservation, setCanRestartObservation] = useState(false);
    const [todayScheduledNotifications, setTodayScheduledNotifications] = useState(0);
    const [extendDialogOpen, setExtendDialogOpen] = useState(false);
    const [missingNotifications, setMissingNotifications] = useState([]);
    const [performanceResponses, setPerformanceResponses] = useState([]);
    const [newTimeSlots, setNewTimeSlots] = useState([]);
    const { t } = useTranslation();

    const calculateProgress = async () => {
        try {
            const scheduledNotifications = await getScheduledNotificationForObservationPeriod(userId);
            const response = await getPerformanceResponses(userId);
            setPerformanceResponses(response.data);

            if (!scheduledNotifications || !scheduledNotifications.dateRanges.length) {
                throw new Error('No scheduled notification date ranges found.');
            }

            const currentDate = new Date();
            let completedDays = 0;
            const uniqueDaysSet = new Set();

            // Iterate through each date range and collect unique dates for total survey days
            scheduledNotifications.dateRanges.forEach((range) => {
                range.notifications.forEach((notif) => {
                    const notifDate = format(new Date(notif.notificationTime), 'yyyy-MM-dd');
                    uniqueDaysSet.add(notifDate);
                });
            });

            const totalDays = uniqueDaysSet.size; // Count of all unique days in the notification set

            // Check if the current date is within any observation period
            const latestEndDate = scheduledNotifications.dateRanges.reduce((latest, range) =>
                isAfter(new Date(range.endDate), latest) ? new Date(range.endDate) : latest,
                new Date(scheduledNotifications.dateRanges[0].endDate)
            );

            if (isAfter(currentDate, latestEndDate)) {
                setIsInObservationPeriod(false); // Set to false if current date is beyond the latest end date
            } else {
                setIsInObservationPeriod(true); // Set to true if current date is within the observation period
            }

            // Logic for setting `todayScheduledNotifications`
            let todayScheduledNotificationsCount = 0;
            scheduledNotifications.dateRanges.forEach((range) => {
                const todayNotifications = range.notifications.filter((notif) =>
                    isSameDay(new Date(notif.notificationTime), currentDate)
                );

                todayScheduledNotificationsCount += todayNotifications.length;
            });

            setTodayScheduledNotifications(todayScheduledNotificationsCount);

            // Iterate through each date range to calculate completed days
            scheduledNotifications.dateRanges.forEach((range) => {
                const rangeStartDate = new Date(range.startDate);
                const rangeEndDate = new Date(range.endDate);
                const rangeUniqueDates = new Set();

                // Collect unique dates within the current date range
                range.notifications.forEach((notif) => {
                    const notifDate = format(new Date(notif.notificationTime), 'yyyy-MM-dd');
                    rangeUniqueDates.add(notifDate);
                });

                if (isAfter(currentDate, rangeEndDate)) {
                    // If the current date is after the end of this range, count all unique days in this range as completed
                    completedDays += rangeUniqueDates.size;
                } else if (currentDate >= rangeStartDate && currentDate <= rangeEndDate) {
                    // If the current date is within the current date range, count days from the start to the current day
                    rangeUniqueDates.forEach((date) => {
                        if (new Date(date) <= currentDate) {
                            completedDays++;
                        }
                    });
                }
            });

            setDaysCompleted(completedDays);
            setTotalSurveyDays(totalDays);
            setSurveyPeriods(scheduledNotifications.dateRanges);
            setLoading(false);

            const missing = scheduledNotifications.dateRanges.flatMap((range) =>
                range.notifications.filter((notif) => {
                    const notifTime = new Date(notif.notificationTime);

                    // Get the start of the current hour
                    const startOfCurrentHour = new Date();
                    startOfCurrentHour.setMinutes(0, 0, 0);

                    const isBeforeNow = isInObservationPeriod ? notifTime < startOfCurrentHour : true; // Exclude the current hour
                    return (
                        isBeforeNow &&
                        !response.data.some((response) =>
                            format(new Date(response.timestamp), 'yyyy-MM-dd HH') === format(notifTime, 'yyyy-MM-dd HH')
                        )
                    );
                })
            );
            setMissingNotifications(missing);



            // Check if enough responses have been recorded
            await checkResponsesCompletion(scheduledNotifications);
        } catch (error) {
            console.error('Error calculating survey progress:', error);
            setLoading(false);
        }
    };

    const handleOpenExtendDialog = () => setExtendDialogOpen(true);
    const handleExtendObservation = async () => {
        try {
            // Map new time slots to ISO strings
            const newNotificationTimes = newTimeSlots.map((slot) => slot.toISOString());
            //    console.log("New Notification Times to Send:", newNotificationTimes);

            // Send only valid ISO strings to the backend
            const response = await extendObservationPeriod(userId, newNotificationTimes);

            toast.success('Observation period extended successfully!');
            await calculateProgress(); // Refresh the data
            setExtendDialogOpen(false);
        } catch (error) {
            console.error('Error extending observation period:', error);
            toast.error('Failed to extend observation period.');
        }
    };


    const fetchTodayPerformance = async () => {
        try {
            const scheduledNotifications = await getScheduledNotificationForObservationPeriod(userId);

            if (!scheduledNotifications || !scheduledNotifications.dateRanges.length) {
                throw new Error('No scheduled notification date ranges found.');
            }

            // Get the latest date range and filter notifications for today
            const latestDateRange = scheduledNotifications.dateRanges.reduce((latest, current) =>
                isAfter(new Date(current.endDate), new Date(latest.endDate)) ? current : latest
            );

            const currentDate = new Date();
            const todayFormatted = format(currentDate, 'yyyy-MM-dd');

            // Filter notifications for today
            const todayNotifications = latestDateRange.notifications.filter((notif) =>
                isSameDay(new Date(notif.notificationTime), currentDate)
            );

            // Get performance responses and filter based on notification times
            const response = await getPerformanceResponses(userId);
            const todayResponses = response.data.filter((entry) => {
                const entryDate = new Date(entry.timestamp);

                // Check if the entry matches any notification time (consider date and hour only)
                return todayNotifications.some((notif) => {
                    const notifDate = new Date(notif.notificationTime);
                    return format(entryDate, 'yyyy-MM-dd HH') === format(notifDate, 'yyyy-MM-dd HH');
                });
            });
            //  console.log(todayResponses)
            setPerformanceResponses(response.data);

            setQuestionsAnsweredToday(todayResponses.length);
        } catch (error) {
            console.error("Error fetching today's performance responses:", error);
        }
    };


    const checkResponsesCompletion = async (notificationData) => {
        try {
            // Ensure notificationData has the expected structure
            if (!notificationData || !notificationData.dateRanges || notificationData.dateRanges.length === 0) {
                console.error('No date ranges found in the notification data.');
                setCanRestartObservation(false);
                return;
            }

            const performanceData = await getPerformanceResponses(userId);

            // Flatten all notifications within the date ranges into a single array
            const allScheduledNotifications = notificationData.dateRanges.flatMap((dateRange) => {
                if (dateRange && dateRange.notifications && Array.isArray(dateRange.notifications)) {
                    return dateRange.notifications.map((notif) => new Date(notif.notificationTime));
                } else {
                    return []; // Handle cases where notifications might be undefined or not an array
                }
            });

            if (allScheduledNotifications.length === 0) {
                console.error('No scheduled notifications found in the date ranges.');
                setCanRestartObservation(false);
                return;
            }

            // Filter performance data to include only responses with timestamps that match notification times
            const responsesMatchingNotifications = allScheduledNotifications.every((notifTime) => {
                return performanceData.data.some((entry) => {
                    const entryDate = new Date(entry.timestamp);
                    return format(entryDate, 'yyyy-MM-dd HH') === format(notifTime, 'yyyy-MM-dd HH');
                });
            });


            if (responsesMatchingNotifications) {
                setCanRestartObservation(true);
            } else {
                setCanRestartObservation(false);
            }
            //  console.log("can restart", canRestartObservation)

        } catch (error) {
            console.error('Error checking response completion:', error);
        }
    };

    const calculateNewTimeSlots = async () => {
        try {
            const scheduledNotifications = await getScheduledNotificationForObservationPeriod(userId);
            const q20Data = await fetchQ20Data(userId);

            if (!scheduledNotifications || !scheduledNotifications.dateRanges.length) {
                throw new Error('No scheduled notification date ranges found.');
            }

            const { startTime, duration } = q20Data; // Working hours
            const [startHour, startMinute] = startTime.split(':').map(Number);
            const endHour = startHour + duration;

            const existingNotificationTimes = new Set(
                scheduledNotifications.dateRanges.flatMap((range) =>
                    range.notifications.map((notif) => format(new Date(notif.notificationTime), 'yyyy-MM-dd HH:mm'))
                )
            );

            const isValidDate = (date) => !isNaN(new Date(date).getTime());

            const missedSlots = missingNotifications
                .filter((notif) => !notif.isExtended && !notif.hasPerformanceScore && isValidDate(notif.notificationTime))
                .map((notif) => {
                    return {
                        ...notif, // Spread the existing properties
                        isExtended: true, // Update the `isExtended` property
                    };
                })
                .map((updatedNotif) => new Date(updatedNotif.notificationTime));

            // console.log('Missed Slots:', missedSlots);

            const endDate = new Date(
                scheduledNotifications.dateRanges.reduce((latest, range) =>
                    isAfter(new Date(range.endDate), latest) ? new Date(range.endDate) : latest,
                    new Date(scheduledNotifications.dateRanges[0].endDate)
                )
            );

            const now = new Date();
            const currentStartDate = now > endDate ? now : endDate;

            let currentDay = currentStartDate; // Start from the later of `endDate` or `now`
            let iterationCount = 0;

            const newSlots = [];
            while (missedSlots.length > 0 && iterationCount < missedSlots.length * 7) {
                while (currentDay.getDay() === 0 || currentDay.getDay() === 6) {
                    currentDay = addDays(currentDay, 1); // Skip weekends
                }

                const validDaySlots = [];
                for (let hour = startHour; hour < endHour; hour++) {
                    const potentialSlot = new Date(currentDay.setHours(hour, 0, 0, 0));
                    if (potentialSlot > now) { // Only include slots after the current time
                        validDaySlots.push(format(potentialSlot, 'yyyy-MM-dd HH:mm'));
                    }
                }


                for (let i = 0; i < missedSlots.length;) {
                    const missedSlot = missedSlots[i];
                    const formattedMissedSlot = format(missedSlot, 'HH:mm');

                    const availableSlot = validDaySlots.find(
                        (slot) =>
                            !existingNotificationTimes.has(slot) &&
                            slot.endsWith(formattedMissedSlot)
                    );

                    if (availableSlot) {
                        newSlots.push(new Date(availableSlot));
                        existingNotificationTimes.add(availableSlot);
                        missedSlots.splice(i, 1); // Remove processed slot
                    } else {
                        i++;
                    }
                }

                currentDay = addDays(currentDay, 1);
                iterationCount++;
            }
            newSlots.sort((a, b) => a - b);
            //  console.log('New Time Slots:', newSlots); // Check final new time slots
            setNewTimeSlots(newSlots);

        } catch (error) {
            console.error('Error calculating new time slots:', error);
        }
    };


    const handleStopWorking = async () => {
        try {
            setLoading(true);
            const response = await deleteDailyNotifications(userId);

            toast.success('Work day stopped successfully! Notifications removed.');

            //   console.log('Stop working response:', response);
            await calculateProgress();
            fetchTodayPerformance();

        } catch (error) {
            console.error('Error stopping work day:', error);
            toast.error('Failed to stop work day. Please try again.');
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        calculateNewTimeSlots();
    }, [missingNotifications]);



    useEffect(() => {
        calculateProgress();
        fetchTodayPerformance();
    }, [userId]);

    const handleRestartSurvey = async () => {
        setLoading(true);
        setConfirmDialogOpen(false);

        try {
            await completeSurvey(userId);

            setDaysCompleted(0);
            setTotalSurveyDays(0);
            setSurveyPeriods([]);
            setQuestionsAnsweredToday(0);
            setIsInObservationPeriod(true);

            setTimeout(async () => {
                await calculateProgress();
                await fetchTodayPerformance();
                setLoading(false);
            }, 1000);
        } catch (error) {
            console.error('Error restarting survey:', error);
            setLoading(false);
            setErrorSnackbarOpen(true);
        }
    };

    const handleOpenConfirmDialog = () => {
        setConfirmDialogOpen(true);
    };

    const handleCloseConfirmDialog = () => {
        setConfirmDialogOpen(false);
    };

    const handleCloseErrorSnackbar = () => {
        setErrorSnackbarOpen(false);
    };

    if (loading) {
        return <Typography>{t('surveyProgress.loading')}</Typography>;
    }

    const percentage = (daysCompleted / totalSurveyDays) * 100;
    const todayPercentage = (questionsAnsweredToday / todayScheduledNotifications) * 100; // Use scheduled notifications as duration

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, padding: 4 }}>
            {showCalendar ? (
                <SurveyCalendar surveyPeriods={surveyPeriods} onReturn={() => setShowCalendar(false)} />
            ) : (
                <>

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box sx={{ width: 100, height: 100 }}>
                            <CircularProgressbar
                                value={percentage}
                                text={`${daysCompleted}/${totalSurveyDays}`}
                                styles={buildStyles({
                                    textSize: '16px',
                                    textColor: '#3e98c7',
                                    pathColor: daysCompleted === totalSurveyDays ? '#4caf50' : '#3e98c7',
                                    trailColor: '#d6d6d6',
                                })}
                            />
                        </Box>

                        <Box sx={{ width: 100, height: 100 }}>
                            <CircularProgressbar
                                value={todayPercentage}
                                text={`${questionsAnsweredToday}/${todayScheduledNotifications}`}
                                styles={buildStyles({
                                    textSize: '16px',
                                    textColor: '#3e98c7',
                                    pathColor: questionsAnsweredToday >= todayScheduledNotifications ? '#4caf50' : '#3e98c7',
                                    trailColor: '#d6d6d6',
                                })}
                            />
                        </Box>


                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleStopWorking}
                        disabled={loading}
                        sx={{ mt: 2 }}
                    >
                        {t('surveyProgress.stopWorking')}
                    </Button>

                    <Typography variant="h6">
                        {t('surveyProgress.daysCompleted', { daysCompleted, totalSurveyDays })}
                    </Typography>
                    <Typography variant="body1">
                        {daysCompleted === totalSurveyDays
                            ? t('surveyProgress.congratulations')
                            : t('surveyProgress.daysLeft', { daysLeft: totalSurveyDays - daysCompleted })}
                    </Typography>

                    {/* Display message if the observation period is over and the survey is incomplete */}
                    {!isInObservationPeriod && !canRestartObservation && (
                        <Typography variant="body2" color="error" sx={{ mt: 2, textAlign: 'center' }}>
                            {t('surveyProgress.incompleteNotice')}
                        </Typography>
                    )}

                    {/* Extend Observation Period Button */}
                    {(!isInObservationPeriod || (isInObservationPeriod && missingNotifications.length > 0)) && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleOpenExtendDialog}
                            disabled={loading}
                            sx={{ mt: 2 }}
                        >
                            {t('surveyProgress.extendObservation')}
                        </Button>
                    )}

                    {/* Restart Survey Period Button */}
                    {!isInObservationPeriod && canRestartObservation && !loading && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenConfirmDialog}
                            disabled={loading}
                            sx={{ mt: 2 }}
                        >
                            {t('surveyProgress.restartSurvey')}
                        </Button>
                    )}

                    {/* Confirm Restart Dialog */}
                    <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
                        <DialogTitle>{t('surveyProgress.confirmRestartTitle')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('surveyProgress.confirmRestartText')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseConfirmDialog}>{t('surveyProgress.cancel')}</Button>
                            <Button onClick={handleRestartSurvey} color="primary">
                                {t('surveyProgress.confirm')}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={extendDialogOpen}
                        onClose={() => setExtendDialogOpen(false)}
                        sx={{
                            '& .MuiDialog-paper': {
                                padding: 2,
                                borderRadius: '10px',
                                maxWidth: '600px',
                                width: '100%',
                            },
                        }}
                    >
                        <DialogTitle
                            sx={{
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                borderBottom: '1px solid #ccc',
                                marginBottom: 2,
                            }}
                        >
                            {t('surveyProgress.extendObservationTitle')}
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                '& ul': {
                                    listStyleType: 'none',
                                    padding: 0,
                                },
                                '& li': {
                                    padding: '8px 12px',
                                    borderRadius: '5px',
                                    marginBottom: '8px',
                                    backgroundColor: '#f9f9f9',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                },
                            }}
                        >
                            {/* Missed Time Slots */}
                            {missingNotifications.length > 0 ? (
                                <>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            marginBottom: '12px',
                                            fontWeight: 'bold',
                                            color: '#3e98c7',
                                        }}
                                    >
                                        {t('surveyProgress.missedTimeSlots')}
                                    </Typography>
                                    <ul>
                                        {missingNotifications.map((notif, index) => (
                                            <li key={index}>
                                                {format(new Date(notif.notificationTime), 'yyyy-MM-dd HH:mm')}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ) : (
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        color: '#d32f2f',
                                    }}
                                >
                                    {t('surveyProgress.noMissingNotifications')}
                                </Typography>
                            )}

                            {/* New Time Slots */}
                            {newTimeSlots.length > 0 && (
                                <>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            marginTop: '20px',
                                            marginBottom: '12px',
                                            fontWeight: 'bold',
                                            color: '#3e98c7',
                                        }}
                                    >
                                        {t('surveyProgress.newTimeSlots')}
                                    </Typography>
                                    <ul>
                                        {newTimeSlots
                                            .filter((slot) => !slot.hasPerformanceScore && !slot.isExtended)
                                            .map((slot, index) => (
                                                <li key={index}>
                                                    {format(slot, 'yyyy-MM-dd HH:mm')}
                                                </li>
                                            ))}
                                    </ul>
                                </>
                            )}
                            {missingNotifications.filter((notif) => notif.hasPerformanceScore === false && notif.isExtended === true).length > 0 && (
                                <>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            marginTop: '20px',
                                            marginBottom: '12px',
                                            fontWeight: 'bold',
                                            color: '#3e98c7',
                                        }}
                                    >
                                        {t('surveyProgress.assignedNotifications')}
                                    </Typography>
                                    <ul>
                                        {missingNotifications
                                            .filter((notif) => notif.hasPerformanceScore === false && notif.isExtended === true)
                                            .map((notif, index) => (
                                                <li key={index}>
                                                    {format(new Date(notif.notificationTime), 'yyyy-MM-dd HH:mm')}
                                                </li>
                                            ))}
                                    </ul>
                                </>
                            )}




                        </DialogContent>
                        <DialogActions
                            sx={{
                                justifyContent: 'center',
                                padding: '16px 24px',
                                borderTop: '1px solid #ccc',
                            }}
                        >
                            <Button
                                onClick={() => setExtendDialogOpen(false)}
                                sx={{
                                    backgroundColor: '#f5f5f5',
                                    color: '#333',
                                    textTransform: 'none',
                                    marginRight: '12px',
                                    '&:hover': {
                                        backgroundColor: '#e0e0e0',
                                    },
                                }}
                            >
                                {t('surveyProgress.cancel')}
                            </Button>
                            <Button
                                onClick={handleExtendObservation}
                                color="primary"
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    padding: '8px 16px',
                                }}
                            >
                                {t('surveyProgress.confirm')}
                            </Button>
                        </DialogActions>
                    </Dialog>


                    {/* Error Snackbar */}
                    <Snackbar
                        open={errorSnackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseErrorSnackbar}
                    >
                        <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                            {t('surveyProgress.restartError')}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </Box>
    );
}
export default SurveyProgress;
