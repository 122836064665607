import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { updateUserSurveyData } from '../../../../services/userService';
import { fetchSurveyResponses } from '../../../../services/surveyService';

const Q19 = ({ userId, onNext, onPrevious, saveResponse }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const options = [
        { value: 6, label: t('questions.q19.options.option1') },
        { value: 4, label: t('questions.q19.options.option2') },
        { value: 2, label: t('questions.q19.options.option3') },
        { value: 0, label: t('questions.q19.options.option4') }
    ];

    const getChronotype = (score) => {
        if (score >= 14 && score <= 30) return 'Definitiver Abendtyp';
        if (score >= 31 && score <= 41) return 'Moderater Abendtyp';
        if (score >= 42 && score <= 58) return 'Neutraltyp';
        if (score >= 59 && score <= 69) return 'Moderater Morgentyp';
        if (score >= 70 && score <= 86) return 'Definitiver Morgentyp';
        return 'Unknown';
    };

    const formatTimeRange = (timeRange) => {
        const times = timeRange.split(/(?<=\d{2}:\d{2} [AP]M)(?=\d{2}:\d{2} [AP]M)/);
        return times.join(' - ');
    };

    const getRecommendations = (chronotype) => {
        const sleep = t(`result.recommendations.${chronotype}.sleep`);
        const wake = t(`result.recommendations.${chronotype}.wake`);

        return {
            sleep: formatTimeRange(sleep),
            wake: formatTimeRange(wake),
            tips: t(`result.recommendations.${chronotype}.tips`),
        };
    };




    useEffect(() => {
        const fetchExistingResponse = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.token) {
                    throw new Error('No token found in localStorage');
                }
                const token = user.token;

                const baseUrl = process.env.REACT_APP_API_URL;

                const response = await axios.get(`${baseUrl}/survey/get-response/q19/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data && response.data.score) {
                    setSelectedOption(response.data);
                }
            } catch (error) {
                console.error('Error fetching existing response:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchExistingResponse();
    }, [userId]);

    const handleOptionChange = (e) => {
        const selectedValue = parseInt(e.target.value);
        const selectedLabel = options.find(option => option.value === selectedValue).label;
        setSelectedOption({ score: selectedValue, description: selectedLabel });
    };

    const handleNext = async () => {
        try {
            saveResponse('q19', selectedOption); // Save the last question's response

            // Fetch all survey responses using fetchSurveyResponses
            const data = await fetchSurveyResponses(userId);

            if (!data) {
                throw new Error('No survey responses found.');
            }

            // Calculate total score
            const totalScore = Object.values(data).reduce((acc, cur) => acc + (cur.score || 0), 0);
            console.log('Responses:', data);
            console.log('Total Score:', totalScore);

            // Calculate chronotype and recommendations
            const chronotype = getChronotype(totalScore);
            const recommendations = getRecommendations(chronotype);

            // Update the user's survey-specific fields
            await updateUserSurveyData(userId, {
                chronotype,
                recommendedSleepTime: recommendations.sleep,
                recommendedWakeUpTime: recommendations.wake,
            });

            // Proceed to the next step
            onNext();
        } catch (error) {
            console.error('Error processing survey results:', error);
            alert(t('questions.q19.errorMessage')); // Show an error message
        }
    };

    if (loading) {
        return <p>{t('questions.q19.loading')}</p>;
    }

    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3>{t('questions.q19.title')}</h3>
            <form>
                {options.map(option => (
                    <div key={option.value}>
                        <input
                            type="radio"
                            id={`option${option.value}`}
                            name="q19"
                            value={option.value}
                            checked={selectedOption && selectedOption.score === option.value}
                            onChange={handleOptionChange}
                        />
                        <label htmlFor={`option${option.value}`}>{option.label} ({option.value})</label>
                    </div>
                ))}
            </form>
            <button onClick={onPrevious} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>{t('questions.q19.previous')}</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button onClick={handleNext} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }} disabled={!selectedOption}>{t('questions.q19.next')}</button>
        </div>
    );
};

export default Q19;
