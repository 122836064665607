import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Box,
    Tooltip,
    IconButton
} from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Line } from 'react-chartjs-2';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';
import DailySurveyChart from './DailySurveyChart/DailySurveyChart';
import { getUserProfile } from '../../../services/userService';
import axios from 'axios';
import Together from "together-ai";

const PreviewAndDownload = ({
    userId,
    performanceResponses,
    dailySurveyResponses,
    observationPeriods,
    selectedObservationPeriod,
    setSelectedObservationPeriod,
}) => {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const surveyChartRefs = useRef([]);
    const [chartData, setChartData] = useState(null);
    const [chronotype, setChronotype] = useState('');
    const [recommendedSleepTime, setRecommendedSleepTime] = useState('');
    const [recommendedWakeUpTime, setRecommendedWakeUpTime] = useState('');

    const BACKEND_PROXY_URL = process.env.REACT_APP_BACKEND_PROXY_URL;


    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const { chronotype, recommendedSleepTime, recommendedWakeUpTime } = await getUserProfile();
                setChronotype(chronotype || 'Not available');
                setRecommendedSleepTime(recommendedSleepTime || 'Not available');
                setRecommendedWakeUpTime(recommendedWakeUpTime || 'Not available');
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, []);


    const processChartData = () => {
        if (!selectedObservationPeriod) return;

        const { notifications } = selectedObservationPeriod;

        // Generate dynamic time slots
        const timeSlots = generateDynamicTimeSlots(notifications);

        // Step 1: Group performance scores by time slot
        const groupedScores = {};
        notifications.forEach(({ notificationTime }) => {
            const notificationDate = new Date(notificationTime);
            const timeSlot = `${String(notificationDate.getHours()).padStart(2, '0')}:00`;

            const matchingResponses = performanceResponses.filter(({ timestamp }) => {
                const responseDate = new Date(timestamp);
                return (
                    responseDate.getHours() === notificationDate.getHours() &&
                    responseDate.toDateString() === notificationDate.toDateString()
                );
            });

            if (!groupedScores[timeSlot]) {
                groupedScores[timeSlot] = [];
            }

            matchingResponses.forEach(({ score }) => {
                groupedScores[timeSlot].push(score);
            });
        });

        // Step 2: Calculate averages for each time slot
        const chartDataPoints = timeSlots.map((timeSlot) => {
            const scores = groupedScores[timeSlot] || [];
            const averageScore =
                scores.length > 0
                    ? scores.reduce((sum, score) => sum + score, 0) / scores.length
                    : null;
            return {
                timeSlot,
                averageScore,
            };
        });

        // Step 3: Prepare chart data
        return {
            labels: chartDataPoints.map((data) => data.timeSlot),
            datasets: [
                {
                    label: 'Performance Score',
                    data: chartDataPoints.map((data) => data.averageScore || 0),
                    borderColor: 'rgba(75,192,192,1)',
                    borderWidth: 2,
                    fill: false,
                },
            ],
        };
    };


    const generateDynamicTimeSlots = (notifications) => {
        // Extract hours from notifications
        const notificationTimes = notifications.map(({ notificationTime }) => new Date(notificationTime));
        const earliestHour = Math.min(...notificationTimes.map((time) => time.getHours()));
        const latestHour = Math.max(...notificationTimes.map((time) => time.getHours()));
        console.log("earliest: ", earliestHour)
        console.log("latest: ", latestHour)

        // Generate time slots based on the range
        return Array.from(
            { length: latestHour - earliestHour + 1 },
            (_, i) => `${String(earliestHour + i).padStart(2, '0')}:00`
        );
    };



    const handlePreview = async () => {
        if (!selectedObservationPeriod) {
            alert('Please select an observation period.');
            return;
        }

        // Generate the required data for charts
        const timeSlots = generateDynamicTimeSlots(selectedObservationPeriod.notifications);
        const { chartData: totalScoresChart, scoresByWeekday } = processTotalScoresByWeekday(performanceResponses);
        const scoresByTimeSlotChart = processScoresByTimeSlot(performanceResponses, timeSlots);
        const performanceLineChart = processChartData();

        // Generate the analysis prompts
        const performanceLineChartPrompt = `
            Analyze the following performance data in conjunction with the user's chronotype "${chronotype}", 
            recommended wake-up time "${recommendedWakeUpTime}", and recommended sleep time "${recommendedSleepTime}":
        
            Performance scores range from 1 to 10:
            - 10 indicates the highest productivity and best performance.
            - 1 indicates the lowest productivity and worst performance.
    
            The chart contains the following time slots and their average performance scores:
            ${performanceLineChart.labels.map((label, index) => `${label}: ${performanceLineChart.datasets[0].data[index]}`).join(', ')}.
    
            Consider how performance scores vary throughout the day and provide insights into the user's productivity patterns. 
            It is not necessary to list all the single values, just focus on pattern or significant values. Do not use "I".
            Do not use "user" in response, use "You" instead. Do not use "suppose"; if a value contains 0, that field is empty without the user's input. Ensure to finish the sentences below 500 tokens. write in consecutive sentences and paragraphs, no bullet points. 
            Offer actionable recommendations to optimize performance and align it with the user's chronotype and recommended sleep schedule.
        `;

        const wakeBedQualityPrompt = `
            Analyze the combined insights from the wake-up time chart, bedtime chart, and sleep quality chart in conjunction with the user's chronotype "${chronotype}", 
            recommended wake-up time "${recommendedWakeUpTime}", and recommended sleep time "${recommendedSleepTime}".

            Do not use "user" in response, use "You" instead. Do not use "suppose"; if a value contains 0, that field is empty without the user's input. 
            It is not necessary to list all the single values, just focus on pattern or significant values.  write in consecutive sentences and paragraphs, no bullet points.
            Do not use "I".
    
            Provide a summary of how these factors align with optimal productivity and actionable suggestions to improve performance and rest. 
            Ensure the response is concise and around 500 tokens, finish the sentences.
        `;

        const totalScoresTimeSlotPrompt = `
        Analyze the following performance data in conjunction with the user's chronotype "${chronotype}", 
        recommended wake-up time "${recommendedWakeUpTime}", and recommended sleep time "${recommendedSleepTime}":

        Here are the performance scores for each weekday:
        ${scoresByWeekday
                .map(
                    ({ weekday, scores }) =>
                        `${weekday}: ${scores.length > 0 ? scores.join(', ') : 'No scores recorded'}`
                )
                .join('\n')}

        Performance scores range from 1 to 10:
        - 10 indicates the highest productivity and best performance.
        - 1 indicates the lowest productivity and worst performance.

        Do not use "user" in response, use "You" instead. Do not use "suppose"; if a value contains 0, that field is empty without the user's input. 
        It is not necessary to list all the single values, just focus on pattern or significant values. 
        write in consecutive sentences and paragraphs, no bullet points. Do not use "I".

        Provide insights into the performance patterns across the week and suggest improvements based on the distribution of scores. Align the recommendations with the user's chronotype and recommended schedule. Ensure the response is under 500 tokens and finish the sentences, keep response compact.
    `;

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        try {
            const performanceLineChartResponse = await fetch(`${BACKEND_PROXY_URL}/proxy/chat/completions`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    messages: [{ role: "system", content: performanceLineChartPrompt }],
                    model: "meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo-128K",
                    max_tokens: 500,
                    temperature: 0.7,
                }),
            });

            await delay(1000); // Add a delay before the next request

            const wakeBedQualityResponse = await fetch(`${BACKEND_PROXY_URL}/proxy/chat/completions`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    messages: [{ role: "system", content: wakeBedQualityPrompt }],
                    model: "meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo-128K",
                    max_tokens: 500,
                    temperature: 0.7,
                }),
            });

            await delay(1000); // Add a delay before the next request

            const totalScoresTimeSlotResponse = await fetch(`${BACKEND_PROXY_URL}/proxy/chat/completions`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    messages: [{ role: "system", content: totalScoresTimeSlotPrompt }],
                    model: "meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo-128K",
                    max_tokens: 500,
                    temperature: 0.7,
                }),
            });

            // Process the responses
            const performanceLineChartAnalysis = (await performanceLineChartResponse.json()).choices[0]?.message?.content?.trim();
            const wakeBedQualityAnalysis = (await wakeBedQualityResponse.json()).choices[0]?.message?.content?.trim();
            const totalScoresTimeSlotAnalysis = (await totalScoresTimeSlotResponse.json()).choices[0]?.message?.content?.trim();

            // Update state with all charts and the analyses
            setChartData({
                performanceLineChart,
                totalScoresChart,
                scoresByTimeSlotChart,
                analysis: performanceLineChartAnalysis,
                wakeBedQualityAnalysis,
                totalScoresTimeSlotAnalysis,
            });

            setOpenDialog(true); // Open dialog to display charts and analysis
        } catch (error) {
            console.error('Error generating analysis:', error);
            alert('Failed to generate analysis.');
        }
    };



    const processTotalScoresByWeekday = (performanceResponses) => {
        const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
        const scoresByWeekday = weekdays.map((weekday, index) => {
            const scores = performanceResponses
                .filter(({ timestamp }) => new Date(timestamp).getDay() === (index + 1))
                .map(({ score }) => score);
            const totalScore = scores.reduce((sum, score) => sum + score, 0); // Sum of scores for the weekday
            return { weekday, scores, totalScore };
        });

        return {
            chartData: {
                labels: weekdays,
                datasets: [
                    {
                        label: 'Total Scores by Weekday',
                        data: scoresByWeekday.map(({ totalScore }) => totalScore),
                        backgroundColor: 'rgba(75,192,192,0.6)',
                        borderColor: 'rgba(75,192,192,1)',
                        borderWidth: 1,
                    },
                ],
            },
            scoresByWeekday,
        };
    };


    const processScoresByTimeSlot = (performanceResponses, timeSlots) => {
        const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
        const datasets = weekdays.map((weekday, index) => {
            const data = timeSlots.map((timeSlot) => {
                const scores = performanceResponses
                    .filter(({ timestamp }) => {
                        const date = new Date(timestamp);
                        const matches = (
                            date.getDay() === (index + 1) &&
                            `${String(date.getHours()).padStart(2, '0')}:00` === timeSlot
                        );
                        if (matches) {
                            //  console.log(`Match for ${weekday} ${timeSlot}:`, date); // Log matches
                        }
                        return matches;
                    })
                    .map(({ score }) => score);

                return scores.length > 0
                    ? scores.reduce((sum, score) => sum + score, 0) / scores.length // Average for the time slot
                    : null;
            });

            return {
                label: weekday,
                data,
                fill: false,
                borderColor: `rgba(${Math.floor(Math.random() * 255)},${Math.floor(
                    Math.random() * 255
                )},${Math.floor(Math.random() * 255)},1)`,
                tension: 0.4,
            };
        });

        return {
            labels: timeSlots,
            datasets,
        };
    };


    const handleDownload = async () => {
        if (
            !chartData ||
            !chartData.analysis ||
            !chartData.wakeBedQualityAnalysis ||
            !chartData.totalScoresTimeSlotAnalysis
        ) {
            alert('Please preview the data before downloading.');
            return;
        }

        const pdf = new jsPDF();
        const pageHeight = pdf.internal.pageSize.height;
        const pageWidth = pdf.internal.pageSize.width;
        let offsetY = 20; // Initial Y position

        const normalizeText = (text) => {
            return text
                .replace(/\s+/g, ' ') // Replace multiple spaces or newlines with a single space
                .trim(); // Remove leading and trailing spaces
        };

        const WRAP_WIDTH = 190;

        // Helper function to add formatted text
        const addFormattedText = (text, options = {}) => {
            const { bold = false, fontSize = 12, marginY = 6 } = options;
            pdf.setFontSize(fontSize);
            pdf.setFont('helvetica', bold ? 'bold' : 'normal');

            // Normalize and split text into lines
            const normalizedText = normalizeText(text); // Ensure consistent formatting
            const lines = pdf.splitTextToSize(normalizedText, WRAP_WIDTH); // Use normalized text for splitting

            // Render each line in the PDF
            lines.forEach((line) => {
                if (offsetY + marginY > pdf.internal.pageSize.height) {
                    pdf.addPage();
                    offsetY = 20; // Reset offset for new page
                }
                pdf.text(line, 10, offsetY); // Render text
                offsetY += marginY; // Add consistent spacing
            });
        };



        // Helper function to add headings
        const addHeading = (heading, fontSize = 14, centered = false) => {
            pdf.setFontSize(fontSize);
            pdf.setFont('helvetica', 'bold');
            const wrappedHeading = pdf.splitTextToSize(heading, 190);
            wrappedHeading.forEach((textLine, index) => {
                if (offsetY + 8 > pageHeight) {
                    pdf.addPage();
                    offsetY = 20;
                }
                const x = centered && index === 0 ? pageWidth / 2 - pdf.getTextWidth(textLine) / 2 : 10;
                pdf.text(textLine, x, offsetY);
                offsetY += 8;
            });
        };

        // Helper function to capture charts
        const captureChart = async (selector) => {
            const element = document.querySelector(selector);
            if (element) {
                const canvas = await html2canvas(element);
                const imgData = canvas.toDataURL('image/png');

                // Calculate the image dimensions
                const imgWidth = 170; // Desired image width
                const imgHeight = 70; // Desired image height (adjust to maintain aspect ratio)

                // Center the image horizontally
                const x = (pdf.internal.pageSize.width - imgWidth) / 2;

                // Ensure the image fits on the current page, or move to a new page
                if (offsetY + imgHeight > pageHeight) {
                    pdf.addPage();
                    offsetY = 20; // Reset Y position for new page
                }

                pdf.addImage(imgData, 'PNG', x, offsetY, imgWidth, imgHeight); // Add image at calculated position
                offsetY += imgHeight + 15; // Move offsetY below the image with margin
            }
        };



        // Parse and add Markdown text
        const parseAndAddMarkdown = (markdown) => {
            const lines = markdown.split('\n').map(normalizeText); // Normalize all lines
            lines.forEach((line) => {
                if (line.startsWith('###')) {
                    const heading = line.replace('### ', '');
                    addHeading(heading, 16, true); // Add heading in the center
                } else if (line.startsWith('*')) {
                    const bulletContent = line.replace('* ', '');
                    const formattedSegments = bulletContent.split(/\*\*(.*?)\*\*/g); // Split into regular and bold segments
                    let finalText = '• ';
                    formattedSegments.forEach((segment, index) => {
                        if (index % 2 === 0) {
                            finalText += segment.trim();
                        } else {
                            addFormattedText(finalText.trim(), { bold: false, fontSize: 12, marginY: 6 });
                            addFormattedText(segment.trim(), { bold: true, fontSize: 12, marginY: 6 });
                            finalText = '';
                        }
                    });
                    if (finalText.trim()) {
                        addFormattedText(finalText.trim(), { bold: false, fontSize: 12, marginY: 6 });
                    }
                } else if (/^\d\./.test(line)) {
                    const numberedSegments = line.split(/\*\*(.*?)\*\*/g);
                    numberedSegments.forEach((segment, index) => {
                        addFormattedText(segment.trim(), { bold: index % 2 !== 0, fontSize: 12, marginY: 6 });
                    });
                } else if (/\*\*(.*?)\*\*/.test(line)) {
                    const boldSegments = line.split(/\*\*(.*?)\*\*/g);
                    boldSegments.forEach((segment, index) => {
                        addFormattedText(segment.trim(), { bold: index % 2 !== 0, fontSize: 12, marginY: 6 });
                    });
                } else {
                    addFormattedText(line, { fontSize: 12, marginY: 6 });
                }
            });
        };

        const ensureNewPageForText = (minSpaceRequired = 50) => {
            // Check if there's enough space for the text
            if (offsetY + minSpaceRequired > pageHeight) {
                pdf.addPage();
                offsetY = 20; // Reset offset for the new page
            }
        };



        // Add title in the center
        addHeading('Your individual analysis', 18, true);

        // Add the performance line chart
        await captureChart('#performance-line-chart');

        // Add analysis text immediately after the performance line chart
        parseAndAddMarkdown(chartData.analysis);

        // Add wake-up time, bedtime, and sleep quality charts on a new page
        pdf.addPage();
        offsetY = 20;
        addHeading('Wake-Up Time, Bedtime, and Sleep Quality Analysis', 16);
        await captureChart('.wake-up-time-chart');
        await captureChart('.bed-time-chart');
        await captureChart('.sleep-quality-chart');
        ensureNewPageForText(100);
        parseAndAddMarkdown(chartData.wakeBedQualityAnalysis);

        // Add total scores and time slot charts on another new page
        pdf.addPage();
        offsetY = 20;
        addHeading('Total Scores and Time Slot Analysis', 16);
        await captureChart('#total-scores-chart-container');
        await captureChart('#time-slot-chart-container');
        parseAndAddMarkdown(chartData.totalScoresTimeSlotAnalysis);

        // Save the PDF
        pdf.save(`Individual Analysis.pdf`);
    };



    return (
        <div>

            <Tooltip title={t('overview.downloadReport')}>
                <IconButton onClick={() => setOpenDialog(true)} >
                    <DownloadIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>{t('overview.selectObservationPeriod')}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>{t('overview.selectPeriod')}</InputLabel>
                        <Select
                            value={selectedObservationPeriod}
                            onChange={(e) => setSelectedObservationPeriod(e.target.value)}
                        >
                            {observationPeriods.map((period, index) => (
                                <MenuItem key={index} value={period}>
                                    {t('overview.observationPeriod', {
                                        index: index + 1,
                                        start: new Date(period.startDate).toLocaleDateString(),
                                        end: new Date(period.endDate).toLocaleDateString(),
                                    })}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {chartData?.performanceLineChart && (
                        <Box id="performance-line-chart">
                            <Typography variant="h6">{t('overview.performanceLineChart')}</Typography>
                            <Line data={chartData.performanceLineChart} />
                        </Box>
                    )}
                    <Box>
                        <Typography variant="h6">Daily Survey Charts</Typography>
                        <DailySurveyChart
                            responses={dailySurveyResponses}
                            onDownloadChart={(refs) => (surveyChartRefs.current = refs)} // Capture chart refs
                        />
                    </Box>
                    {chartData?.totalScoresChart && (
                        <Box id="total-scores-chart-container">
                            <Typography variant="h6">{t('Total Scores Chart')}</Typography>
                            <Line data={chartData.totalScoresChart} />
                        </Box>
                    )}
                    {chartData?.scoresByTimeSlotChart && (
                        <Box id="time-slot-chart-container">
                            <Typography variant="h6">{t('overview.scoresByTimeSlotChart')}</Typography>
                            <Line data={chartData.scoresByTimeSlotChart} />
                        </Box>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="secondary">
                        {t('overview.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            handlePreview(); // Generate charts for preview
                        }}
                        color="primary"
                        disabled={!selectedObservationPeriod}
                    >
                        {t('overview.preview')}
                    </Button>
                    <Button onClick={handleDownload} color="primary" disabled={!selectedObservationPeriod}>
                        {t('overview.download')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PreviewAndDownload;
