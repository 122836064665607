import React, { useState, useEffect } from 'react';
import { getPerformanceResponses } from '../../../../services/performanceService';
import { getScheduledNotificationForObservationPeriod } from '../../../../services/notificationsService';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import './ObservationPeriods.css';

const ObservationPeriods = ({ userId, onSelectPeriod }) => {
    const [periods, setPeriods] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPeriodIndex, setCurrentPeriodIndex] = useState(0);
    const { t } = useTranslation();
    const timeZone = 'Europe/Berlin';

    useEffect(() => {
        const fetchObservationPeriods = async () => {
            try {
                const [scheduledNotifications, performanceData] = await Promise.all([
                    getScheduledNotificationForObservationPeriod(userId),
                    getPerformanceResponses(userId)
                ]);


                if (!performanceData || !performanceData.data || !Array.isArray(performanceData.data)) {
                    throw new Error("Invalid performance data structure");
                }

                if (!scheduledNotifications || !scheduledNotifications.dateRanges.length) {
                    throw new Error('No scheduled notification date ranges found.');
                }

                const calculatedPeriods = scheduledNotifications.dateRanges.map((range) => {
                    const startDate = moment(range.startDate).tz(timeZone).toDate();
                    const endDate = moment(range.endDate).tz(timeZone).toDate();

                    // Directly use the notification times without extra check for first day
                    const todayNotifications = range.notifications.map(notif => moment(notif.notificationTime).tz(timeZone));
                    const expectedQuestions = range.notifications.length;

                    // Use only the scheduled slots for counting responses
                    const scheduledSlots = todayNotifications;

                    // Filter responses to match scheduled slots
                    const responsesInPeriod = performanceData.data.filter((response) => {
                        const responseDate = moment(response.timestamp).tz(timeZone);
                        return scheduledSlots.some(slot => responseDate.isSame(slot, 'hour'));
                    });


                    const completedQuestions = responsesInPeriod.length;

                    return {
                        start: startDate,
                        end: endDate,
                        completedQuestions,
                        expectedQuestions,
                        notifications: todayNotifications
                    };
                });

                setPeriods(calculatedPeriods);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching observation periods:', error);
                setLoading(false);
            }
        };

        fetchObservationPeriods();
    }, [userId, timeZone]);


    const handlePeriodClick = (index) => {
        onSelectPeriod(periods[index]);
    };

    if (loading) {
        return <Typography>{t('overview.loading')}</Typography>;
    }


    return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            {periods.length > 0 && (
                <>
                    <Card
                        variant="outlined"
                        className="observation-card"
                        sx={{
                            mb: 2,
                            p: 2,
                            borderRadius: 2,
                            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                            '&:hover': {
                                transform: 'scale(1.03)',
                                boxShadow: 4,
                                backgroundColor: '#f0f8ff',
                            },
                            cursor: 'pointer'
                        }}
                        onClick={() => handlePeriodClick(currentPeriodIndex)}
                    >
                        <CardContent>
                            <Typography variant="body2">
                                {t('overview.observationPeriod', {
                                    index: currentPeriodIndex + 1,
                                    start: periods[currentPeriodIndex].start.toLocaleDateString(),
                                    end: periods[currentPeriodIndex].end.toLocaleDateString(),
                                })}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                {t('overview.completedQuestions', {
                                    completed: periods[currentPeriodIndex].completedQuestions,
                                    expected: periods[currentPeriodIndex].expectedQuestions
                                })}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Button
                            variant="contained"
                            onClick={() => setCurrentPeriodIndex(prev => Math.max(prev - 1, 0))}
                            disabled={currentPeriodIndex === 0}
                        >
                            {t('overview.previous')}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => setCurrentPeriodIndex(prev => Math.min(prev + 1, periods.length - 1))}
                            disabled={currentPeriodIndex === periods.length - 1}
                        >
                            {t('overview.next')}
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ObservationPeriods;
